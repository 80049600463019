// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pay-per-use-tsx": () => import("./../../../src/pages/pay-per-use.tsx" /* webpackChunkName: "component---src-pages-pay-per-use-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-terroir-tsx": () => import("./../../../src/pages/terroir.tsx" /* webpackChunkName: "component---src-pages-terroir-tsx" */),
  "component---src-pages-vale-dos-vinhedos-tsx": () => import("./../../../src/pages/vale-dos-vinhedos.tsx" /* webpackChunkName: "component---src-pages-vale-dos-vinhedos-tsx" */)
}

